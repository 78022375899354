@font-face {
  font-family: 'material-outline-icons';
  src:
    url('~devextreme/dist/css/icons/dxiconsmaterial.ttf') format('truetype'),
    url('~devextreme/dist/css/icons/dxiconsmaterial.woff') format('woff'),
    url('~devextreme/dist/css/icons/dxiconsmaterial.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
.dx-icon:before {
  font-family: 'material-outline-icons' !important;
}
