@import '~devextreme/dist/css/dx.common';
@import '~devextreme/dist/css/dx.material.blue.light';
input[type=date].dx-texteditor-input:before {
  content: none;
}
.dx-link.dx-link-icon svg {
  font-size: 18px;
}
.dx-toolbar-item-content {
  & > label {
    margin-top: 0
  }
}
.dx-datagrid-focus-overlay {
  display: none;
  border: 0px;
}
.dx-editor-cell.dx-focused {
  //border-bottom: 2px solid #35baf6 !important;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 2px;
    background-color: #35baf6;
  }
}
.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-field-item-content {
  white-space: normal;
  line-height: 1.5;
}
.dx-field-item-label-location-top {
  padding-left: 0;
}
.dx-datagrid .dx-link {
  color: #1f88e5;
}
.dx-link--disabled.dx-link {
  color: #777;
  pointer-events: none;
}
.dx-command-edit,
.dx-editor-cell {
  padding: 0 !important;
}
.dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages .dx-selection {
  background-color: #1f88e5;
}
.dx-datagrid .dx-row > td:not(.dx-command-adaptive):not(.dx-command-edit):not([style="text-align: right;"]) {
  text-align: left !important;
}
.dx-texteditor-input-container input {
  border-left: none;
  border-right: none;
}
.dx-datagrid-action.dx-cell-focus-disabled {
  text-align: left !important;
}
.dx-field-toolbar-item-label {
  color: #6c757d;
  font-size: 12px;
  margin-right: 10px;
  display: inline-block;
}
.dx-toolbar-item-content {
  .dx-toolbar-item-content__wrapper {
    display: flex;
    align-items: center;
    margin: 0;
    .dx-field-toolbar-item-date {
      max-width: 150px;
      &.dateFrom {
        margin-right: 10px;
      }
      &.dateTo {
        margin-left: 10px;
      }
    }
    .dx-texteditor-input {
      padding: 6px;
    }
  }
}

.dx-datagrid .dx-row > td {
  padding: 0;
}

.dx-datagrid .dx-datagrid-table .dx-header-row > td {
  padding: 12px 16px;
}

.cell {
  &_container {
    padding: 14px 16px;
    min-height: 48px;
  }
}

.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-field-item-content .cell_container {
  padding: 0;
}

.dx-datagrid-column-chooser .dx-overlay-content.dx-popup-normal.dx-popup-draggable.dx-resizable {
  transform: translate(0, 0);
  right: 0!important;
  left: auto!important;
}

.page--employee_list .dx-datagrid-column-chooser .dx-overlay-content.dx-popup-normal.dx-popup-draggable.dx-resizable {
  transform: translate(0, 0) !important;
}

.dx-datagrid .custom-control-input {
  left: 0 !important;
}
