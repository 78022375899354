@import '~sweetalert2/src/sweetalert2.scss';

.swal2-popup.swal2-toast {
  padding: 5px 10px;
  .swal2-icon {
    margin: 0 -7px 0 0;
  }
  .swal2-close {
    align-self: auto;
    margin-top: 3px;
  }
  .swal2-title {
    line-height: 1;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  .swal2-timer-progress-bar {
    &.error {
      background-color: red;
    }
    &.success {
      background-color: green;
    }
  }
}

.swal2-container {
  z-index: 2070;
}
